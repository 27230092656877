import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss']
})
export class ErrorDisplayComponent implements OnInit {
  @Input() status: string = '';
  @Input() message: string = '';

  constructor(private router: Router) {
    const errorData = this.router.getCurrentNavigation().extras.state;
    this.status = errorData?.status;
    this.message = errorData?.message;
  }

  ngOnInit(): void {}
}
