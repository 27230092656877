export const appUrl = 'https://dev-marketplace.mox.io';

export const environment = {
  production: false,
  metamaskForwarderOrigin: `${appUrl}/connect-wallet`,
  firebase: {
    apiKey: "AIzaSyBHPgFaj57NK3N85jKCoJ0ubNKBdS6fh5o",
    authDomain: "mega-cat-labs-329521.firebaseapp.com",
    projectId: "mega-cat-labs-329521",
    storageBucket: "mega-cat-labs-329521.appspot.com",
    messagingSenderId: "807719898434",
    appId: "1:807719898434:web:749a0337777fe9d4294cdb",
    measurementId: "G-TWNZKYK227"
  },
  apiUrl: 'https://dev-playground.mox.io',
  bloxApiUrl: 'https://dev-blox.mox.io',
  openseaUrl: 'https://testnets.opensea.io',
  app: 'MCL',
  polygonChain: 'MUMBAI',
  ethereumChain: 'GOERLI',
  ereaderUrl: 'https://dev-rewards.mox.io',
  auctions: true,
  etherscanUri: 'https://goerli.etherscan.io/',
  polygonscanUri: 'https://mumbai.polygonscan.com/',
  faviconPath: 'assets/images/mcl/favicon.png'
};
