<div class="card" [style.background]="collection.bgColor">
  <div class="preview" style="background: url({{ collection.imageUrl }})" (click)="openCollection()"></div>
  <div class="details" (click)="openCollection()">
    <div class="info">
      <span class="title" [style.color]="collection.accentColor">
        {{ collection.name }}
      </span>
      <span class="label" [style.color]="collection.accentColor">{{ this.walletService.truncateAddress(collection.smartContractAddress) }}</span>
    </div>
  </div>
</div>
