import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product } from './product';
import { environment } from 'environments/environment';
import { WalletService } from '../wallet/wallet.service';
import { AuthService } from '../auth/auth.service';
const baseUrl = environment.apiUrl;

export interface WalletAddressPayload {
  walletAddress: any;
}

@Injectable()
export class ProductService {
  constructor(private httpClient: HttpClient, private walletService: WalletService, private authService: AuthService) {}

  createProduct(formData: FormData): Observable<any> {
    if (!formData.has('name')) {
      throw new Error('Product name is not present');
    }

    if (!formData.has('description')) {
      throw new Error('Product description is not present');
    }

    if (!formData.has('walletAddress')) {
      throw new Error('Wallet Address is not present');
    }

    if (!formData.has('properties')) {
      throw new Error('Product properties are not present');
    }

    try {
      JSON.parse(formData.get('properties').toString());
    } catch (err) {
      throw new Error('Properties are not a valid JSON string');
    }

    return this.httpClient.post(`${baseUrl}/product/create/`, formData, {
      headers: this.authService.getAuthHeader()
    });
  }

  createCollection(formData: FormData): Observable<any> {
    if (!formData.has('name')) {
      throw new Error('Collection name is not present');
    }

    if (!formData.has('description')) {
      throw new Error('Collection description is not present');
    }

    if (!formData.has('ownerAddress')) {
      throw new Error('Owner Address is not present');
    }

    if (!formData.has('symbol')) {
      throw new Error('Collection symbol is not present');
    }

    return this.httpClient.post(`${baseUrl}/product/createCollection/`, formData, { headers: this.authService.getAuthHeader() });
  }

  editCollection(formData: FormData): Observable<any> {
    if (!formData.has('collectionId')) {
      throw new Error('Collection id is not present');
    }

    if (!formData.has('name')) {
      throw new Error('Collection name is not present');
    }

    if (!formData.has('description')) {
      throw new Error('Collection description is not present');
    }

    if (!formData.has('subheading')) {
      throw new Error('Collection subheading is not present');
    }

    return this.httpClient.post(`${baseUrl}/product/editCollection`, formData, { headers: this.authService.getAuthHeader() });
  }

  fetchCollectionMetadata(formData: FormData): Observable<any> {
    if (!formData.has('address')) {
      throw new Error('Collection address is not present');
    }
    if (!formData.has('chain')) {
      throw new Error('Collection blockchain is not present');
    }

    return this.httpClient.post(`${baseUrl}/product/fetchCollectionMetadata/`, formData, { headers: this.authService.getAuthHeader() });
  }

  getCollections(data: WalletAddressPayload): Observable<any> {
    if (!data.walletAddress) {
      throw new Error('Wallet Address is not present');
    }

    return this.httpClient.post(`${baseUrl}/product/getCollections/`, data, { headers: this.authService.getAuthHeader() });
  }

  getCollectionDetailV2(chain: string, smartContractAddress: string): Observable<any> {
    return this.httpClient.get(`${baseUrl}/product/fetchCollectionMetadata/${chain}/${smartContractAddress}`, { headers: this.authService.getAuthHeader() });
  }

  getCollectionDetail(address: string, chain: string): Observable<any> {
    if (!address) {
      throw new Error('Smart Address is not present');
    }

    if (!chain) {
      throw new Error('Chain is not present');
    }

    const data = {
      smartContractAddress: address,
      chain: chain
    };
    return this.httpClient.post(`${baseUrl}/product/getCollectionDetail/`, data, { headers: this.authService.getAuthHeader() });
  }

  getCollectionsByLinkedWallets(): Observable<any> {
    const user = this.authService.user;
    return this.httpClient.get(`${baseUrl}/product/getCollectionsByUserLinkedWallets/${user.id}`, { headers: this.authService.getAuthHeader() });
  }

  getAllCollections = (): Observable<any> => this.httpClient.get(`${baseUrl}/product/getAllCollections`);

  getHistory(tokenId: string, collectionId: string): Observable<any> {
    if (!tokenId || !collectionId) {
      throw new Error('Data is not present');
    }
    return this.httpClient.get(`${baseUrl}/order/getHistory/${tokenId}/${collectionId}`);
  }

  getUserOfCollection(smartContractAddress: string): Observable<any> {
    if (!smartContractAddress) {
      throw new Error('smartContractAddress is not present');
    }
    return this.httpClient.post(`${baseUrl}/product/getOneCollection/`, { smartContractAddress });
  }

  getProduct(id: string): Observable<Product> {
    if (!id) {
      throw new Error('Invalid Id');
    }

    return this.httpClient.get<Product>(`${baseUrl}/product/token/${id}`, { headers: this.authService.getAuthHeader() });
  }

  updateMetadata(formData: FormData): Observable<any> {
    if (!formData.has('name')) {
      throw new Error('Name is not present');
    }

    if (!formData.has('description')) {
      throw new Error('Description is not present');
    }

    if (!formData.has('id')) {
      throw new Error('Id is not present');
    }

    if (!formData.has('properties')) {
      throw new Error('Properties are not present');
    }

    return this.httpClient.post(`${baseUrl}/product/updateMeta`, formData, { headers: this.authService.getAuthHeader() });
  }

  getNFTMetadata(id: string): Observable<any> {
    return this.httpClient.get(`${baseUrl}/product/getNFtMedata/${id}`, { headers: this.authService.getAuthHeader() });
  }

  listingNFT(): Observable<any> {
    const walletAddress = this.walletService.currentAccount;

    return this.httpClient.get(`${baseUrl}/product/getNFTsBasedOnWalletAddress/${walletAddress}`, { headers: this.authService.getAuthHeader() });
  }

  fetchAllNFTsByWallet(walletAddress): Observable<any> {
    return this.httpClient.get(`${baseUrl}/product/getNFTsBasedOnWalletAddress/${walletAddress}`, { headers: this.authService.getAuthHeader() });
  }

  listingNFTByLinkedWallets(): Observable<any> {
    return this.httpClient.get(`${baseUrl}/product/getNFTsBasedOnUserWalletAddressesByUser`, { headers: this.authService.getAuthHeader() });
  }

  listingNFTByContractAddress(contractAddress, chain = 'eth', next = ''): Observable<any> {
    return this.httpClient.get(`${baseUrl}/product/getNFTsBasedOnContractAddress/${chain}/${contractAddress}/${next}`, { headers: this.authService.getAuthHeader() });
  }

  /**
   * Creates an Offer (Listing) from an NFT.
   *
   * Returns the result of Get prepared Approve tx. If result is [], then no approval is required.
   * If the result is not, then approval is required.
   *
   * @param data
   */
  createForSale(formData: FormData): Observable<any> {
    if (!formData.has('tokenId')) {
      throw new Error('Token id is not present');
    }

    if (!formData.has('address')) {
      throw new Error('Address is not present');
    }

    if (!formData.has('sellerAddress')) {
      throw new Error('Seller Address is not present');
    }

    if (!formData.has('price')) {
      throw new Error('Price is not present');
    }

    if (!formData.has('chain')) {
      throw new Error('Chain is not present');
    }

    return this.httpClient.post(`${baseUrl}/product/createSaleOffer/`, formData, { headers: this.authService.getAuthHeader() });
  }

  /**
   * Fetches all listings (offers) owned by Mega Cat Labs marketplace. Filtering must be implemented in UI.
   *
   * @returns An array of listings.
   */
  getAllListings(filter?: string, auctionOffers: boolean = false): Observable<any> {
    const filterQuery = filter ? `?filter=${filter}` : '';
    const auctionOfferQuery = filter ? `&auctionOffers=${auctionOffers}` : `?auctionOffers=${auctionOffers}`;

    return this.httpClient.get(`${baseUrl}/product/listSaleOffers${filterQuery}${auctionOfferQuery}`, { headers: this.authService.getAuthHeader() });
  }

  getAllReadyListings = () => this.getAllListings('READY');

  getMarketListings(category: string, filters: any): Observable<any> {
    let name = '';
    let minPrice = '';
    let maxPrice = '';
    let collection = '';

    if (filters.name) {
      name = filters.name;
    }
    if (filters.minPrice) {
      minPrice = filters.minPrice;
    }
    if (filters.maxPrice) {
      maxPrice = filters.maxPrice;
    }
    if (filters.collection) {
      collection = filters.collection;
    }
    const filterQuery = `name=${name}&minPrice=${minPrice}&maxPrice=${maxPrice}&collection=${collection}`;

    if (category === 'sale') {
      return this.httpClient.get(`${baseUrl}/product/listSaleOffers?filter=READY&auctionOffers=false&${filterQuery}`, { headers: this.authService.getAuthHeader() });
    } else if (category === 'auction') {
      return this.httpClient.get(`${baseUrl}/auctions/?${filterQuery}`, { headers: this.authService.getAuthHeader() });
    }
    return null;
  }

  editListing(data): Observable<any> {
    return this.httpClient.post(`${baseUrl}/product/editOffer`, data, { headers: this.authService.getAuthHeader() });
  }

  cancelListing(data): Observable<any> {
    return this.httpClient.post(`${baseUrl}/product/cancelOffer`, data, { headers: this.authService.getAuthHeader() });
  }

  specificOffer(id: string): Observable<any> {
    return this.httpClient.get(`${baseUrl}/product/offer/${id}`, { headers: this.authService.getAuthHeader() });
  }

  getStats(): Observable<any> {
    return this.httpClient.get(`${baseUrl}/product/getStats`, { headers: this.authService.getAuthHeader() });
  }

  getOrderHistory(): Observable<any> {
    return this.httpClient.get(`${baseUrl}/order/`, { headers: this.authService.getAuthHeader() });
  }
  getNFTMetadataByContract(contractAddress: string, tokenId: string): Observable<any> {
    return this.httpClient.get(`${baseUrl}/product/getNftMetadataByContract/${contractAddress}/${tokenId}`, { headers: this.authService.getAuthHeader() });
  }
}
