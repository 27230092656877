import { NgModule } from '@angular/core';
import { CollectionCardComponent } from 'app/modules/elements/collection-card/collection-card.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CollectionCardComponent],
  imports: [CommonModule],
  exports: [CommonModule, CollectionCardComponent]
})
export class CollectionCardModule {}
