import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { WalletService } from 'app/core/wallet/wallet.service';

@Component({
  selector: 'app-collection-card',
  templateUrl: './collection-card.component.html',
  styleUrls: ['./collection-card.component.scss']
})
export class CollectionCardComponent implements OnInit {
  @Input() collection;
  @Output() loading = new EventEmitter();

  constructor(private router: Router, public walletService: WalletService) {}

  ngOnInit(): void {}

  openCollection() {
    if (this.collection.previewButtonUrl?.includes('https')) {
      this.router.navigateByUrl(this.collection.previewButtonUrl);
    } else {
      this.router.navigate(this.buildCollectionLink(this.collection));
    }
  }

  /**
   * TODO: Determine where to put the code for determining the chain, it should
   * just determine if its an Ethereum chain of Polygon chain (and Solana in future)
   *
   * @param collection Collection model that contains smart contract and other useful information
   * @returns an array of strings for the routerLink component
   */
  buildCollectionLink(collection: any): string[] {
    const routerPath = ['/', 'collection'];
    routerPath.push(collection.chain);
    routerPath.push(collection.smartContractAddress);

    return routerPath;
  }

  imageError() {
    this.collection.image = 'assets/images/mcl/no-cat.png';
  }
}
