<!-- Footer -->
<footer>
  <!-- Section: Links  -->
  <section>
    <div class="container text-md-start mt-5">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <!-- Removed (via ngIf) per Ty's request on Feb/19/2022. Leaving for future usage. -->
          <div *ngIf="false" class="row">
            <div class="row">
              <h3 class="text-color h2 strong">Be a BookCoin Insider & Get Launch Perks</h3>
            </div>
            <div class="row mt-5">
              <div class="col-md-8 mt-2">
                <input type="text" class="input-style form-control" placeholder="Email" />
              </div>
              <div class="col-md-4 mt-2">
                <button class="btn btn-primary button-style">Subscribe</button>
              </div>
            </div>
          </div>
          <!-- Grid row -->
          <div class="row mt-3" *ngIf="innerWidth >= 760">
            <!-- Grid column -->
            <div class="col-md-3 mt-8">
              <!-- Links -->
              <h5 class="fw-bold mb-2 bkcn-footer"><img src="assets/bookcoin/ml-logo.svg" style="height: 30px; width: 130px; filter: invert(1)" /></h5>
              <p>
                <button class="text-reset">Powered x <a href="http://bookcoin.com" target="_blank" style="color: #c19a5b">BookCoin</a>™</button>
              </p>
              <p>
                <button class="text-reset">©2022 <a href="http://bookcoin.com" target="_blank" style="color: #c19a5b">BookCoin</a>™</button>
              </p>
            </div>
            <!-- Grid column -->
            <div class="col-md-6">
              <div class="d-flex">
                <!-- Grid column -->
                <div class="mt-8 mr-auto">
                  <!-- Links -->
                  <h5 class="fw-bold mb-2 heading-color">Community</h5>
                  <p>
                    <a href="https://discord.gg/metalibrary" class="text-reset text-decoration-none" target="_blank">Discord</a>
                  </p>
                  <p>
                    <a href="https://twitter.com/bookcoinHQ" class="text-reset text-decoration-none" target="_blank">Twitter</a>
                  </p>
                  <p>
                    <a href="https://help.bookcoin.com/" class="text-reset text-decoration-none" target="_blank">Help Desk</a>
                  </p>
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="mt-8 mr-auto">
                  <!-- Links -->
                  <h5 class="fw-bold mb-2 heading-color">Get Involved</h5>
                  <p>
                    <a href="https://www.bookcoin.com/mint/founder-cards" class="text-reset text-decoration-none" target="_blank">Become a Founder</a>
                  </p>
                  <p>
                    <a href="https://help.bookcoin.com/collaborate/knZ28RptGau8gGczZkVV78" class="text-reset text-decoration-none" target="_blank">Partner with Us</a>
                  </p>
                  <p>
                    <a href="https://help.bookcoin.com/collaborate/knZ28RptGau8gGczZkVV78" class="text-reset text-decoration-none" target="_blank">List Your Collection</a>
                  </p>
                </div>
                <!-- Grid column -->
              </div>
            </div>

            <!-- Grid column -->
            <div class="col-md-3 mt-8">
              <!-- Links -->
              <h5 class="fw-bold mb-2 heading-color">Legal</h5>
              <p>
                <a href="https://help.bookcoin.com/legal/uAwesWqn2qCz1wgk9UEGTu/terms-of-service/oraomuxzD5A3nyoNjxmCSE" class="text-reset text-decoration-none" target="_blank"
                  >Terms of use</a
                >
              </p>
              <p>
                <a href="https://help.bookcoin.com/legal/uAwesWqn2qCz1wgk9UEGTu/privacy-policy/64nKP7FrEz28sayAqZfaXQ" class="text-reset text-decoration-none" target="_blank"
                  >Privacy Policy</a
                >
              </p>
            </div>
            <!-- Grid column -->
          </div>
          <!-- Grid row -->
          <div class="row mt-3" *ngIf="innerWidth < 760">
            <!-- Grid column -->
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <!-- Grid column -->
                <div class="d-flex flex-column align-items-center">
                  <!-- Links -->
                  <h5 class="fw-bold mb-2 heading-color">Community</h5>
                  <p>
                    <a href="https://discord.gg/metalibrary" class="text-reset text-decoration-none" target="_blank">Discord</a>
                  </p>
                  <p>
                    <a href="https://twitter.com/bookcoinHQ" class="text-reset text-decoration-none" target="_blank">Twitter</a>
                  </p>
                  <p>
                    <a href="https://help.bookcoin.com/" class="text-reset text-decoration-none" target="_blank">Help Desk</a>
                  </p>
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="d-flex flex-column align-items-center">
                  <!-- Links -->
                  <h5 class="fw-bold mb-2 heading-color">Get Involved</h5>
                  <p>
                    <a href="https://www.bookcoin.com/mint/founder-cards" class="text-reset text-decoration-none" target="_blank">Become a Founder</a>
                  </p>
                  <p>
                    <a href="https://help.bookcoin.com/collaborate/knZ28RptGau8gGczZkVV78" class="text-reset text-decoration-none" target="_blank">Partner with Us</a>
                  </p>
                  <p>
                    <a href="https://help.bookcoin.com/collaborate/knZ28RptGau8gGczZkVV78" class="text-reset text-decoration-none" target="_blank">List Your Collection</a>
                  </p>
                </div>
                <!-- Grid column -->
                <div class="d-flex flex-column align-items-center">
                  <!-- Links -->
                  <h5 class="fw-bold mb-2 heading-color">Legal</h5>
                  <p>
                    <a href="https://help.bookcoin.com/legal/uAwesWqn2qCz1wgk9UEGTu/terms-of-service/oraomuxzD5A3nyoNjxmCSE" class="text-reset text-decoration-none" target="_blank"
                      >Terms of use</a
                    >
                  </p>
                  <p>
                    <a href="https://help.bookcoin.com/legal/uAwesWqn2qCz1wgk9UEGTu/privacy-policy/64nKP7FrEz28sayAqZfaXQ" class="text-reset text-decoration-none" target="_blank"
                      >Privacy Policy</a
                    >
                  </p>
                </div>
                <!-- Grid column -->
              </div>
            </div>
            <!-- Grid column -->
            <div class="col-md-3 mt-8">
              <!-- Links -->
              <h5 class="fw-bold mb-2 bkcn-footer"><img src="assets/bookcoin/ml-logo.svg" style="height: 30px; width: 130px; filter: invert(1)" /></h5>
              <p>
                <button href="#!" class="text-reset">Powered x <a href="http://bookcoin.com" target="_blank">BookCoin</a>™</button>
              </p>
              <p>
                <button href="#!" class="text-reset">©2022 <a href="http://bookcoin.com" target="_blank">BookCoin</a>™</button>
              </p>
            </div>
            <!-- Grid column -->
          </div>
          <!-- Grid row -->
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </section>
</footer>
<!-- Footer -->
