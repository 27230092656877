import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverseTruncate'
})
export class ReverseTruncatePipe implements PipeTransform {
  transform(value: string, length = 25, errorMessage = 'Value not found'): string {
    if (!Boolean(value) || !Boolean(value.length)) {
      return errorMessage;
    }

    if (value.length <= length) {
      return value;
    }

    return value.substr(value.length - length, value.length);
  }
}
