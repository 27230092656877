import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';

const favIcon: HTMLLinkElement = document.querySelector('#appIcon');
favIcon.href = environment.faviconPath;

const setupMclTemplate = () => {
  const splash = document.createElement('fuse-splash-screen');
  splash.innerHTML = `<img src="assets/images/logo/MarketPlace.png" alt="Marketplace Logo" />
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>`;
  document.body.append(splash);
};

const setupBKCNTemplate = () => {
  const splash = document.createElement('fuse-splash-screen');
  splash.innerHTML = `<img src="assets/bookcoin/bkcn-logo-square.png" alt="BookCoin Logo" />
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>`;
  document.body.append(splash);
};

if (environment.production) {
  enableProdMode();
}

if (environment.app === 'MCL') {
  setupMclTemplate();
}

if (environment.app === 'BKCN') {
  setupBKCNTemplate();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
