<div class="row">
  <div class="col-sm-12 text-center">
    <h1>Success!</h1>
    <div class="col-sm-12" *ngFor="let token of state.tokenIds; let i = index">
      <a href="{{ openSeaLinks[i] }}" target="_blank" rel="noopener noreferrer">
        <button class="view-on-opensea btn btn-primary">View on OpenSea</button>
      </a>
      <p style="margin-top: 10px">
        <a href="{{ txLinkBase + state.transactions[0].tx }}" target="_blank" rel="noopener noreferrer"
          >{{ state.transactions[0].tx | slice: 0:4 }}...{{ state.transactions[0].tx | slice: -4 }}</a
        >
      </p>
    </div>
  </div>
</div>
