<div class="modal-style content-style mcl-card" role="dialog">
  <div class="modal-body modal-style">
    <div class="card mcl-card px-5 py-5">
      <div class="card-body">
        <h3 class="heading">Update Password</h3>
        <div class="d-flex flex-col">
          <div class="flex flex-col image">
            <label class="label mb-3">Password</label>
            <input class="mcl-input" id="password" (change)="onChange($event)" placeholder="Enter New Password" type="password" (keyup.enter)="updatePassword()" />
          </div>
          <div class="flex flex-col image mt-4">
            <label class="label mb-3">Confirm Password</label>
            <input class="mcl-input" id="cpassword" (change)="onUpdate($event)" placeholder="Enter Confirm Password" type="password" (keyup.enter)="updatePassword()" />
          </div>
          <div class="flex flex-col mt-2 error-message" *ngIf="errorMsg1">Please fill all the Fields</div>
          <div class="flex flex-col error-message" *ngIf="errorMsg">Password dosn't match</div>
          <div class="flex items-center justify-end mt-5">
            <button class="btn btn-primary mcl-button ml-3" data-dismiss="modal" type="submit" (click)="updatePassword()">Change</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
