import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { RouteMonitorService } from './shared/route-monitor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /**
   * Constructor
   */
  constructor(private routeMonitorService: RouteMonitorService, private titleService: Title) {
    if (environment.app === 'MCL') {
      titleService.setTitle('MarketPlace');
    } else if (environment.app === 'BKCN') {
      titleService.setTitle('MetaLibrary');
    }
  }
}
