<div class="modal-style content-style mcl-card" role="dialog">
  <div class="modal-body modal-style">
    <div class="card mcl-card px-5 py-5">
      <div class="card-body">
        <h3 class="heading">Update Email</h3>
        <div class="d-flex flex-col">
          <div class="flex flex-col image">
            <label class="label mb-3">Email</label>
            <input class="mcl-input" id="email" (change)="onChange($event)" placeholder="Enter New Email" type="email" (keyup.enter)="updateEmail()" />
          </div>
          <div class="flex items-center justify-end mt-5">
            <button class="btn btn-primary mcl-button ml-3" data-dismiss="modal" type="submit" (click)="updateEmail()">Change</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
