import { TruncatePipe } from './truncate.pipe';
import { NgModule } from '@angular/core';
import { ReverseTruncatePipe } from './reverse-truncate.pipe';

@NgModule({
  imports: [],
  declarations: [TruncatePipe, ReverseTruncatePipe],
  exports: [TruncatePipe, ReverseTruncatePipe]
})
export class CustomPipesModule {
  static forRoot() {
    return {
      ngModule: CustomPipesModule,
      providers: []
    };
  }
}
