<!-- Footer -->
<footer>
  <!-- Section: Links  -->
  <section>
    <div class="container-fluid text-center text-md-start mt-5">
      <!-- Grid row -->
      <div class="row mt-3">
        <!-- Grid column -->
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <!-- <img src="assets/images/logo/mcl_logo.png" alt="Mega Cat Studios  Logo" class="footer-logo" />
          <p>
            We are an independent video game development studio with a global team. At our core, we are passionate game developers and artists who seek to create meaningful
            experiences through our games and services.
          </p> -->
          <img src="assets/images/logo/MarketPlace.png" alt="Moxio Logo" class="footer-logo" />
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-8">
          <!-- Links -->
          <h6 class="text-uppercase fw-bold mb-2 heading-color">EXPLORE</h6>
          <p>
            <a href="#!" class="text-reset">Buy</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Sell</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Trade</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Search</a>
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-8">
          <!-- Links -->
          <h6 class="text-uppercase fw-bold mb-2 heading-color">DEVELOPERS</h6>
          <p>
            <a href="#!" class="text-reset">FAQ</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Docs</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Stats</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Create</a>
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-8">
          <!-- Links -->
          <h6 class="text-uppercase fw-bold mb-2 heading-color">CONTACT</h6>
          <p>
            <a href="#!" class="text-reset">Hello</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Email</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Discord</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Support</a>
          </p>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
      <hr style="color: white" />
      <div class="site-wrapper secondary-footer">
        <div class="copyright">
          <span>Moxio, Inc. All rights reserved © 2022</span>
        </div>
        <nav>
          <li><a href="#">Privacy Policy</a></li>
          <li><a href="#">Terms of Service</a></li>
        </nav>
      </div>
    </div>
  </section>
</footer>
<!-- Footer -->
