<header>
  <div class="nav-links">
    <nav style="background-color: rgba(255, 255, 255, 0)" class="navbar navbar-expand-lg header_background {{ this.isDarkMode ? 'dark-nav' : 'light-nav' }}">
      <div class="container" style="height: 70px">
        <div class="site-identity">
          <h2>
            <a style="font-family: Neue World" [routerLink]="['/']" class="bkcn-icon"><img src="assets/bookcoin/metalibrary-beta-logo.png" /></a>
          </h2>
        </div>
        <button
          class="navbar-toggler toggle-button nav-toggle"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-expanded="false"
          (click)="isCollapsed = !isCollapsed"
          aria-label="Toggle navigation"
        >
          <span class="line"></span>
          <span class="line"></span>
          <span class="line" style="margin-bottom: 0"></span>
        </button>
        <div class="collapse navbar-collapse" [attr.aria-expanded]="!isCollapsed" [ngClass]="{ collapse: isCollapsed }">
          <div class="me-auto mb-2 mb-lg-0"></div>
          <ul class="navbar-nav nav-allcaps">
            <li *ngIf="!this.bookcoinLauncherService.may18thLaunchFeatureOn" class="menu-item" (click)="isCollapsed = !isCollapsed">
              <a [routerLink]="['/collections']" class="list-color"><strong>Collections</strong></a>
            </li>
            <li class="menu-item" (click)="isCollapsed = !isCollapsed; $event.stopPropagation()">
              <a target="_blank" rel="noopener noreferrer" [href]="communityLink" class="list-color"><strong>Community</strong></a>
            </li>
            <li class="menu-item" (click)="isCollapsed = !isCollapsed; $event.stopPropagation()">
              <a target="_blank" rel="noopener noreferrer" [href]="helpDeskLink" class="list-color"><strong>Help Desk</strong></a>
            </li>

            <li *ngIf="!isLoggedIn()" class="menu-item" (click)="isCollapsed = !isCollapsed">
              <a [routerLink]="['/sign-in']" class="list-color"><strong>Sign In</strong> </a>
            </li>
            <li *ngIf="isLoggedIn() && this.walletService.currentAccount" (click)="isCollapsed = !isCollapsed">
              <a [routerLink]="['/profile']" class="list-color"><strong>Library</strong></a>
            </li>
            <li *ngIf="isLoggedIn() && !this.walletService.currentAccount" (click)="isCollapsed = !isCollapsed">
              <a [routerLink]="['/wallet-connect']" class="list-color"><strong>Library</strong></a>
            </li>
            <li *ngIf="isLoggedIn()" class="menu-item" (click)="isCollapsed = !isCollapsed">
              <a [routerLink]="['/sign-out']" class="list-color"><strong>Sign Out</strong></a>
            </li>
            <li *ngIf="isLoggedIn() && isAdminOrSuperUser()" class="menu-item admin-item" (click)="isCollapsed = !isCollapsed">
              <a [routerLink]="['/dashboard']" class="list-color"><strong>Admin</strong></a>
            </li>
            <li *ngIf="this.getProductsCount() !== 0" class="menu-item" (click)="isCollapsed = !isCollapsed">
              <a [routerLink]="['/cart']" class="list-color">
                <strong>CART</strong>
              </a>
            </li>
            <li *ngIf="!this.walletService.isMetaMaskInstalled()" class="menu-item wallet-item" (click)="isCollapsed = !isCollapsed">
              <a [routerLink]="" (click)="this.walletService.beginMetaMaskOnboarding()" class="list-color" color="primary"> Install </a>
              <a *ngIf="this.walletService.currentAccount && !this.bookcoinLauncherService.may18thLaunchFeatureOn" [routerLink]="" class="list-color" color="primary">
                {{ walletAddress | slice: 0:5 }}...{{ walletAddress | slice: -4 }}
              </a>
            </li>
            <!-- <li *ngIf="this.walletService.isMetaMaskInstalled() && !this.walletService.currentAccount" class="menu-item wallet-item" (click)="isCollapsed = !isCollapsed">
              <a [routerLink]="['/wallet-connect']" class="list-color" color="primary"> Connect </a>
            </li> -->
            <li
              *ngIf="this.walletService.currentAccount && !this.bookcoinLauncherService.may18thLaunchFeatureOn"
              class="menu-item wallet-item"
              (click)="isCollapsed = !isCollapsed"
            >
              <a [routerLink]="" class="list-color" color="primary"> {{ walletAddress | slice: 0:5 }}...{{ walletAddress | slice: -4 }} </a>
            </li>
            <li>
              <span (click)="toggleDarkMode()" style="cursor: pointer; height: 30px; width: 30px">
                <img class="toggle-mode" alt="ToggleMode" src="/assets/icons/navbar-toggle.png" style="height: 30px; width: 30px" />
              </span>
            </li>
          </ul>
          <!-- Artifact from Subtle Art launch; BKCN client may still want this?-->
          <ul *ngIf="false">
            <a *ngIf="this.walletService.currentAccount" [routerLink]="" class="list-color">
              <strong>{{ walletAddress | slice: 0:5 }}...{{ walletAddress | slice: -4 }}</strong>
            </a>
            <button *ngIf="!this.walletService.currentAccount" class="connect-wallet btn btn-primary" (click)="this.walletService.connectToMetaMask()">Connect Wallet</button>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</header>
