import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() properties;
  @Input() label;

  idleStyles = '';
  hoverStyles = '';
  clickStyles = '';
  btnState: string = 'idle';

  constructor() {}

  ngOnInit(): void {
    this.idleStyles = `background-image: url(${this.properties?.idle.src}); background-repeat: no-repeat;background-size: contain;${this.properties?.idle.attr}`;
    this.hoverStyles = `background-image: url(${this.properties?.hover.src}); background-repeat: no-repeat;background-size: contain;${this.properties?.hover.attr}`;
    this.clickStyles = `background-image: url(${this.properties?.click.src}); background-repeat: no-repeat;background-size: contain;${this.properties?.click.attr}`;
  }
}
