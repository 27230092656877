import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BookcoinLauncherService {
  may18thLaunchFeatureOn: boolean = true;

  constructor() {}
}
